<template>
    <tippy :placement="props.tippyPlacement"
           theme="tip-info"
           trigger="click"
           interactive
           animation="fade"
           @create="assignTippyButtonRole">
        <template #default="attrs">
            <button v-bind="attrs"
                    :class="isGameEndScreen ? 'quiz-button--intro share-animated-button' : 'quiz-button--correct'"
                    class="px-3"
                    @click="shareValuesUpdate">
                <template v-if="isCertificate">
                    <i class="fa-solid fa-share mr-1" />
                    <Translate translationKey="quests-dashboard.shareCertificate"
                               fallback="Share Certificate" />
                </template>
                <template v-else-if="isGameEndScreen">
                    <i class="fa-solid fa-share mr-1 text-uppercase" />
                    <Translate translationKey="general.share"
                               fallback="SHARE" />
                </template>
                <translate v-else
                           translation-key="quests-dashboard.shareIt"
                           fallback="Share It" />
            </button>
        </template>
        <template #content>
            <div class="info share-tippy-content"
                 :class="{ 'nft-icons' : isNft }">
                <div class="subtitle">
                    <Translate translationKey="quests-dashboard.shareItNowOn"
                               fallback="Share it NOW on:" />
                </div>
                <div class="share-now d-flex justify-content-center align-items-center">
                    <div class="social-icons d-flex justify-content-center align-items-center">
                        <span class="i-background mr-2"
                              @click="share(shareTippyStore.twitterShareLink)">
                            <i class="fa-brands fa-x-twitter" />
                        </span>
                        <span class="i-background mr-2"
                              @click="share(shareTippyStore.facebookShareLink)">
                            <i class="fa-brands fa-facebook-f" />
                        </span>
                        <span class="i-background mr-2"
                              @click="share(shareTippyStore.linkedInShareLink)">
                            <i class="fa-brands fa-linkedin-in" />
                        </span>
                        <span class="i-background mr-2"
                              @click="share(shareTippyStore.telegramShareLink)">
                            <i class="fa-kit fa-telegram" />
                        </span>
                        <span class="i-background"
                              @click="shareTippyStore.copyShareText">
                            <i class="fa-solid fa-copy" />
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </tippy>
</template>

<script setup lang="ts">
    import { Tippy, TippyOptions } from 'vue-tippy';
    import { useTooltipStore } from '@/js/store/quests/game/modules/TippyMobileStore';
    import { useShareTippyStore } from '@/js/store/quests/dashboard/ShareTippyStore';
    import { assignTippyButtonRole } from '@/js/helpers/tippyManager';

    const props = defineProps({
        shareUrl: {
            type: String,
            required: false,
            default: '',
        },
        shareText: {
            type: String,
            required: false,
            default: '',
        },
        isCertificate: {
            type: Boolean,
            required: false,
            default: false,
        },
        isGameEndScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        isNft: {
            type: Boolean,
            default: false,
        },
        tippyPlacement: {
            type: String as () => TippyOptions['placement'],
            required: false,
            default: 'bottom',
        },
    });

    const tooltipStore = useTooltipStore();
    const shareTippyStore = useShareTippyStore();

    shareTippyStore.encodedShareText = encodeURIComponent(`${props.shareText}\n`);
    shareTippyStore.encodedShareUrl = encodeURIComponent(props.shareUrl);

    shareTippyStore.twitterShareLink = `https://x.com/intent/tweet?url=${shareTippyStore.encodedShareUrl}&text=${shareTippyStore.encodedShareText}`;
    shareTippyStore.facebookShareLink = `https://www.facebook.com/share.php?u=${shareTippyStore.encodedShareUrl}`;
    shareTippyStore.linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${shareTippyStore.encodedShareUrl}`;
    shareTippyStore.telegramShareLink = `https://t.me/share/url?url=${shareTippyStore.encodedShareUrl}&text=${shareTippyStore.encodedShareText}`;

    const share = (shareLink: string) => {
        window.open(shareLink, 'share_window', 'height=600, width=600');
    };

    const shareValuesUpdate = () => {
        shareTippyStore.setShareText(props.shareText);
        shareTippyStore.setShareUrl(props.shareUrl);
        shareTippyStore.setEncodedShareText(encodeURIComponent(`${props.shareText}\n`));
        shareTippyStore.setEncodedShareUrl(encodeURIComponent(props.shareUrl));
        shareTippyStore.setShareLinksUpdate();
        tooltipStore.setShareTooltip(true);
    };
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";

.quiz-button--correct,
.quiz-button--intro {
    min-width: 112px;
}

.share-animated-button {
    position: relative;
    overflow: hidden;
    animation: pulsing 1.7s linear infinite;

    @keyframes pulsing {
        0% {
            box-shadow: 0 0 0 rgba($baseWhite, 0.6);
            transform: scale(1);
        }
        50% {
            box-shadow: 0 0 20px rgba($baseWhite, 0.8);
            transform: scale(1.05);
        }
        100% {
            box-shadow: 0 0 0 rgba($baseWhite, 0.6);
            transform: scale(1);
        }
    }
}
</style>

<style lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/partials/breakpoints";
@import "@/sass/design-system/variables";

.share-tippy-content {
    .subtitle {
        padding-bottom: 16px;
        color: $baseWhite;
        text-align: center;
    }
}

.share-now {
    font-size: 13px;
    font-weight: 500;

    .social-icons {
        i {
            color: $baseWhite;
            font-size: 17px;
        }

        svg {
            width: 17px;
            fill: $baseWhite;
        }
    }

    .i-background {
        display: flex;
        width: 45px;
        height: 45px;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        border-radius: 50px;
        box-shadow: 0 0 8px 0 rgba(180, 180, 180, 0.5);
        text-align: center;
        transition: 0.4s ease-in-out;

        &:nth-child(1) {
            background-color: $baseBlack;
        }

        &:nth-child(2) {
            background-color: $facebook-color;
        }

        &:nth-child(3) {
            background-color: $linkedin-color;
        }

        &:nth-child(4) {
            background-color: $telegram-color;
        }

        &:nth-child(5) {
            background-color: $purple;
        }

        &:hover {
            border: 1px solid $muted-lavender;
            cursor: pointer;
            opacity: 0.9;
        }
    }
}

.nft-icons {
    @media (min-width: $lg-breakpoint) and (max-width: $xl-breakpoint) {
        .subtitle {
            padding-bottom: 12px;
        }

        .i-background {
            width: 32px;
            height: 32px;

            i {
                font-size: 14px;
            }
        }
    }
}
</style>
