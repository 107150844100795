import { defineStore } from 'pinia';
import { copyShareText } from '@/js/quests/dashboard/helpers/shareHelper';

interface ShareTippyState {
    shareText: string | null,
    shareUrl: string | null,
    encodedShareText: string | null,
    encodedShareUrl: string | null,
    twitterShareLink: string | null,
    facebookShareLink: string | null,
    linkedInShareLink: string | null,
    telegramShareLink: string | null,
}

export const useShareTippyStore = defineStore({
    id: 'shareTippy',
    state: (): ShareTippyState => ({
        shareText: null,
        shareUrl: null,
        encodedShareText: null,
        encodedShareUrl: null,
        twitterShareLink: null,
        facebookShareLink: null,
        linkedInShareLink: null,
        telegramShareLink: null,
    }),
    actions: {
        share(shareLink: string) {
            window.open(shareLink, 'share_window', 'height=600, width=600');
        },
        setShareText(text: string) {
            this.shareText = text;
        },
        setShareUrl(url: string) {
            this.shareUrl = url;
        },
        setEncodedShareText(text: string) {
            this.encodedShareText = text;
        },
        setEncodedShareUrl(url: string) {
            this.encodedShareUrl = url;
        },
        setShareLinksUpdate() {
            this.twitterShareLink = `https://x.com/intent/tweet?url=${this.encodedShareUrl}&text=${this.encodedShareText}`;
            this.facebookShareLink = `https://www.facebook.com/share.php?u=${this.encodedShareUrl}`;
            this.linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${this.encodedShareUrl}`;
            this.telegramShareLink = `https://t.me/share/url?url=${this.encodedShareUrl}&text=${this.encodedShareText}`;
        },
        copyShareText() {
            copyShareText(this.shareText, this.shareUrl);
        },
    },
});
